import { OptionBox } from "./OptionBox";
import { Button } from "./Button";
import { useState, useRef, useEffect } from "react";
import { isValidated } from "./helpers";
import look from "./look.json";
import {find} from "lodash";

export function LookStep(props) {


  const onChange = (event) => {
    // Overrides default value for Estate grill which does not have it, future implementation should automatically select lowest
    if(event.target.value == "EST"){props.setSizeOption("30")}
    else {props.setSizeOption("36")};
    props.setSelectedOption(event.target.value);
  };

  useEffect(()=>{
    props.setPhotos(find(look.options, {value: props.selectedOption}).photos)
  },[])

  useEffect(()=>{
    props.setPrice(find(look.options, {value: props.selectedOption})["total-price-usd"]);
  },[])

  return (
    <>
      <form>
        <h2 className="f37-bold uppercase h2 text-3xl md:text-2xl mb-6">
          Choose Your Look
        </h2>
        <div className="space-y-6">
          {look.options.map((option) => (
            <OptionBox
              key={option.value}
              type={option.type}
              name={option.name}
              value={option.value}
              title={option.title}
              price={option["total-price-usd"]}
              subtitle={option.subtitle}
              onClick={() => {
                props.setPhotos(option.photos);
                props.setPrice(option["total-price-usd"]);
              }}
              selectedOption={props.selectedOption}
              onChange={onChange}
            />
          ))}
        </div>
      </form>
      <div
        dangerouslySetInnerHTML={{
          __html: props.body || look["body-override"],
        }}
      />
      <Button
        text="Continue"
        nextStep={() => {
          props.nextStep(isValidated(props.selectedOption));
        }}
      />
    </>
  );
}
