import { OptionBox } from './OptionBox';
import { Button } from './Button';
import {isValidated} from './helpers';
import fuel from './fuel.json';
import { numberFormat } from './numberFormat';


export function FuelStep(props) {

const onChange = (event) => {
    props.setSelectedOption(event.target.value);
}

  return (
    <>
      <div>
        <h2 className="f37-bold uppercase h2 text-3xl md:text-2xl mb-6">
          Choose Your Fuel
        </h2>
        <div className="space-y-6">
          {fuel.options.map((option) => (
            <OptionBox
              type={option.type}
              name={option.name}
              key={option.value}
              float={numberFormat(props.price)}
              value={option.value}
              title={option.title}
              subtitle={option.subtitle}
              selectedOption={props.selectedOption}
              onChange={onChange}
            />
          ))}
        </div>
      </div>
      <Button
        text="Continue"
        nextStep={() => {
          props.nextStep(isValidated(props.selectedOption));
        }}
      />
    </>
  );
}
