import { OptionBox } from './OptionBox';
import { Button } from './Button';
import {isValidated} from './helpers';
import style from './style.json';
import { numberFormat } from './numberFormat';
import {useEffect, useState} from 'react';
import {find} from 'lodash';

export function StyleStep(props) {

useEffect(()=>{
  props.setPrice(find(style[props.lookOption][props.sizeOption].options, {"value": props.selectedOption})["total-price-usd"]);
})

useEffect(()=>{
  props.setPhotos(find(style[props.lookOption][props.sizeOption].options, {"value": props.selectedOption})["photos"]);
},[])

const onChange = (event) => {
    props.setSelectedOption(event.target.value);
}

  return (
    <>
      <div>
        <h2 className="f37-bold uppercase h2 text-2xl mb-6">
          Choose Your Style
        </h2>
        <div className="space-y-6">
          {style[props.lookOption][props.sizeOption].options.map((option) => (
            <OptionBox
              type={option.type}
              key={option.value}
              name={option.name}
              float={numberFormat(option["total-price-usd"])}
              value={option.value}
              title={option.title}
              subtitle={option.subtitle}
              selectedOption={props.selectedOption}
              onClick={() => {
                props.setPhotos(option.photos);
                props.setPrice(option["total-price-usd"]);
              }}
              onChange={onChange}
            />
          ))}
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: props.body || style["body-override"],
        }}
      />
      <Button
        text="Continue"
        nextStep={() => {
          props.nextStep(
            isValidated(props.selectedOption || props.selectedOption == "")
          );
        }}
      />
    </>
  );
}
