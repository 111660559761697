export function Navigation(props) {
  function NavItem({ text, selected }) {
    return (
      <div
        className={`
          border-t-2
          pt-2
          cursor-pointer
          flex-grow-0
          pp-neue-montreal-book
          ${selected ? "border-gray-800" : ""}`}
      >
        <div
          className={`uppercase text-small pp-neue-montreal-book`}
          onClick={() => {
            props.setStep(text);
          }}
        >
          {text}
        </div>
      </div>
    );
  }
  return (
    <nav className="md:w-3/5 mx-10 md:mx-auto grid md:grid-flow-col text-center md:space-x-4 ">
      {props.titles.map((title, title_idx) => { return <NavItem key={title_idx} selected={props.currentStep === title_idx} text={title} />; })}
    </nav>);
}
