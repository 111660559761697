import { useEffect, useRef } from "react";
import styles from "./OptionBox.module.css";

export function OptionBox(props) {
  const radioRef = useRef(null);
  const labelRef = useRef(null);

  useEffect(() => {
    // Set default value
    if (props.type) {
      if (props.selectedOption === props.value) {
        radioRef.current.checked = true;
        labelRef.current.classList.add("selected");
      } else {
        labelRef.current.classList.remove("selected");
      }
    }
  }, [props.selectedOption]);

  useEffect(() => {
    // Set default value
    if (props.outline) {
      labelRef.current.classList.add("selected");
    }
  }, [props.outline]);

  return (
    <label
      onClick={props.onClick}
      ref={labelRef}
      className={`
          block
          border-2
          p-4
          hover-border-blue-501
          transition-colors
          duration-500`}
    >
      <span
        htmlFor={props.value}
        className="
              uppercase
              pp-supply-mono-regular
              inline"
      >
        {props.title}&nbsp;
      </span>
      {props.type ? (
        <input
          className="hidden"
          ref={radioRef}
          onChange={props.onChange}
          value={props.value}
          type={props.type}
          name={props.name}
          id={props.value}
        />
      ) : (
        ""
      )}
      {props.float ? (
        <span className="inline-block float-right font-light">
          {props.float}
        </span>
      ) : (
        ""
      )}

      <span
        className="
              block
              text-xs
              text-gray-400
              pp-neue-montreal-book"
      >
        {props.price
          ? `Starting at ${props.price
              .toLocaleString("en-US", { style: "currency", currency: "USD" })
              .replace(".00", "")}`
          : props.subtitle}
      </span>
      {props.children ? (
        <span className="mt-3 block">{props.children}</span>
      ) : (
        <span />
      )}
    </label>
  );
}
