import { OptionBox } from './OptionBox';
import { Button } from './Button';
import { useState, useEffect } from 'react';
import skuMap from "./sku-map.json";
import Client from "shopify-buy";
import classNames from "classnames";

export function BuildSummaryStep(props) {



  var builtSku = props.builtSku();

  const [shopifyClient, setShopifyClient] = useState(Client.buildClient({
    domain: "american-made-grills.myshopify.com/",
    storefrontAccessToken: "1f6f2527bb3f695f5d4a2617b55f318d",
    appId: "313272008904",
  }));

  const [loadingCheckout, setLoadingCheckout] = useState(false);

  const lineItemsToUpdate = {
    lineItems: [
      {
        variantId: props.variantId(),
        quantity: 1
      }
    ]
  };

  let openCheckout = () => {
    setLoadingCheckout(true);
    shopifyClient.checkout.create(lineItemsToUpdate).then((checkout) => {
      setLoadingCheckout(false);
      return window.open(checkout.webUrl)
    }, error);
  }

  let error = () => {
    setLoadingCheckout(false);
    console.error("The promise was rejected")
  }


  return (
    <>
      <div>
        <h2 className="f37-bold uppercase h2 text-3xl md:text-2xl mb-6">
          Your Grill
        </h2>
        <div className="space-y-6">
          <OptionBox
            title={`${
              { MUS: "Muscle", ENC: "Encore", EST: "Estate" }[props.lookOption]
            } Grill`}
            color="#"
            outline="true"
          >
            <ul className="list-disc list-inside pp-neue-montreal-book">
              {skuMap[builtSku].features.map((bulletItem) => (
                <li>{bulletItem}</li>
              ))}
            </ul>
            <strong className="font-semibold">Includes:</strong>
            <ul className="list-disc list-inside">
              {skuMap[builtSku].includes.map((bulletItem) => (
                <li>{bulletItem}</li>
              ))}
            </ul>
          </OptionBox>
          <OptionBox outline="true" title="Added Bonus">
            <ul className="list-disc list-inside">
              {skuMap[builtSku]["added-bonus"].map((bulletItem) => (
                <li>{bulletItem}</li>
              ))}
            </ul>
          </OptionBox>
        </div>
      </div>
      <div></div>
      <button
        onClick={() => openCheckout()}
        className="
          bg-blue-501
          pp-supply-mono-regular
          font-white
          text-white
          py-3
          px-12
          inline-flex
          items-center"
      >
        <svg
          className={classNames({
            "w-6": true,
            "h-6": true,
            hidden: loadingCheckout,
          })}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
          />
        </svg>
        <svg
          className={classNames({
            "w-6": true,
            "h-6": true,
            "m-0": true,
            "animate-spin": loadingCheckout,
            hidden: loadingCheckout == false,
          })}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
          />
        </svg>
        <span className="mx-2">Checkout</span>
        <div
          className="
              w-4
              inline-block
              -mb-0.5"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M17 8l4 4m0 0l-4 4m4-4H3"
            />
          </svg>
        </div>
      </button>
    </>
  );
}
